
import { Component, Vue } from "vue-property-decorator";
import MobileWarning from "@/components/MobileWarning.vue";
import Header from "@/components/common/Header.vue";
import Flag from "@/components/common/Flag.vue";

@Component({
  components: { Flag, Header, MobileWarning },
})
export default class RegisterTeam extends Vue {}
