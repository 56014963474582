
import { Component, Vue } from "vue-property-decorator";
import StartDate from "@/components/StartDate.vue";
import Partners from "@/components/Partners.vue";
import TerminalPreview from "@/components/TerminalPreview.vue";
import AsciiTerminalPreview from "@/components/AsciiTerminalPreview.vue";
import CookieBanner from "@/components/CookieBanner.vue";
@Component({
  components: {
    CookieBanner,
    AsciiTerminalPreview,
    TerminalPreview,
    Partners,
    StartDate,
  },
})
export default class CaptureIfYouCan extends Vue {}
