
import { Component, Vue } from "vue-property-decorator";

@Component({})
export default class Partners extends Vue {
  public loadImg(path: string): string {
    return require("@/assets/images/partners/" + path);
  }
  get partners(): any[] {
    return [
      { position: "ORGANIZER", logo: "logo-wp.svg" },
      { position: "GOLD SPONSOR", logo: "logo-volksbank.svg" },
      { position: "SILVER SPONSOR", logo: "logo-lango.svg" },
      { position: "", logo: "logo-agilimo.svg" },
      { position: "BRONZE SPONSORS", logo: "logo-icinga.svg" },
      { position: "", logo: "logo-loacker.svg" },
      { position: "", logo: "logo-wuerth.svg" },
    ];
  }
}
