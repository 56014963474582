
import { Component, Vue } from "vue-property-decorator";
import { TrackingService } from "@/core/Services/TrackingService/TrackingService";

@Component({})
export default class CookieBanner extends Vue {
  visible = true;

  mounted(): void {
    if (TrackingService.userAccepted() || TrackingService.userRejected()) {
      this.visible = false;
    }
  }

  accept(): void {
    TrackingService.conset();
    this.visible = false;
  }

  reject(): void {
    TrackingService.negate();
    this.visible = false;
  }
}
