
import { Component, Vue } from "vue-property-decorator";
import MobileWarning from "@/components/MobileWarning.vue";
import Header from "@/components/common/Header.vue";
import ChallengeTerminal from "@/components/ChallengeTerminal.vue";
import { mobileOrTablet } from "@/utils/mobileUtils";
import RegistrationForm from "@/components/RegistrationForm.vue";
import Flag from "@/components/common/Flag.vue";

@Component({
  components: {
    Flag,
    RegistrationForm,
    ChallengeTerminal,
    Header,
    MobileWarning,
  },
  methods: { mobileOrTablet },
})
export default class RegisterTeam extends Vue {
  timeToShow = false;
  challengeSolved = false;
  formHtml = "";

  mounted(): void {
    console.log(
      "$$$$$$$$\\           $$\\ $$\\                         \n" + // eslint-disable-line
      "$$  _____|          $$ |$$ |                              \n" + // eslint-disable-line
      "$$ |       $$$$$$\\  $$ |$$ | $$$$$$\\  $$\\  $$\\  $$\\         \n" + // eslint-disable-line
      "$$$$$\\    $$  __$$\\ $$ |$$ |$$  __$$\\ $$ | $$ | $$ |     \n" + // eslint-disable-line
      "$$  __|   $$ /  $$ |$$ |$$ |$$ /  $$ |$$ | $$ | $$ |     \n" + // eslint-disable-line
      "$$ |      $$ |  $$ |$$ |$$ |$$ |  $$ |$$ | $$ | $$ |     \n" + // eslint-disable-line
      "$$ |      \\$$$$$$  |$$ |$$ |\\$$$$$$  |\\$$$$$\\$$$$  |    \n" + // eslint-disable-line
      "\\__|       \\______/ \\__|\\__| \\______/  \\_____\\____/   "); // eslint-disable-line

    console.log("   $$ |    $$ |                                                            \n" + // eslint-disable-line
      "   $$ |    $$ |                              \n" + // eslint-disable-line
      "$$$$$$$\\   $$$$$$$\\   $$$$$$\\               \n" + // eslint-disable-line
      "\\_$$  _|   $$  __$$\\ $$  __$$\\              \n" + // eslint-disable-line
      "  $$ |     $$ |  $$ |$$$$$$$$ |               \n" + // eslint-disable-line
      "  $$ |$$\\  $$ |  $$ |$$   ____|             \n" + // eslint-disable-line
      "  \\$$$$  | $$ |  $$ |\\$$$$$$$\\             \n" + // eslint-disable-line
      "    \\____/ \\__|  \\__| \\_______|"); // eslint-disable-line
    // eslint-disable-line
    console.log("              $$\\       $$\\   $$\\                                               \n" + // eslint-disable-line
      "              $$ |      \\__|  $$ |                                         \n" + // eslint-disable-line
      "$$\\  $$\\  $$\\ $$$$$$$\\  $$\\ $$$$$$\\    $$$$$$\\        \n" + // eslint-disable-line
      "$$ | $$ | $$ |$$  __$$\\ $$ |\\_$$  _|  $$  __$$\\              \n" + // eslint-disable-line
      "$$ | $$ | $$ |$$ |  $$ |$$ |  $$ |    $$$$$$$$ |             \n" + // eslint-disable-line
      "$$ | $$ | $$ |$$ |  $$ |$$ |  $$ |$$\\ $$   ____|           \n" + // eslint-disable-line
      "\\$$$$$\\$$$$  |$$ |  $$ |$$ |  \\$$$$  |\\$$$$$$$\\            \n" + // eslint-disable-line
      " \\_____\\____/ \\__|  \\__|\\__|   \\____/  \\_______|"); // eslint-disable-line

    console.log("                    $$\\       $$\\       $$\\   $$\\           \n" + // eslint-disable-line
      "                    $$ |      $$ |      \\__|  $$ |          \n" + // eslint-disable-line
      "$$$$$$\\   $$$$$$\\   $$$$$$$\\  $$$$$$$\\  $$\\ $$$$$$\\         \n" + // eslint-disable-line
      "$$  __$$\\  \\____$$\\ $$  __$$\\ $$  __$$\\ $$ |\\_$$  _|        \n" + // eslint-disable-line
      "$$ |  \\__| $$$$$$$ |$$ |  $$ |$$ |  $$ |$$ |  $$ |          \n" + // eslint-disable-line
      "$$ |      $$  __$$ |$$ |  $$ |$$ |  $$ |$$ |  $$ |$$\\       \n" + // eslint-disable-line
      "$$ |      \\$$$$$$$ |$$$$$$$  |$$$$$$$  |$$ |  \\$$$$  |      \n" + // eslint-disable-line
      "\\__|       \\_______|\\_______/ \\_______/ \\__|   \\____/"); // eslint-disable-line

    console.log("in the DOM/Inspector...");

    const comment = document.createComment(
      "There must be a white rabbit in there"
    );
    document.body.insertBefore(comment, document.body.firstChild);

    this.timeToShow = true;
  }

  private onChallengeSolved(formHtml: string) {
    this.challengeSolved = true;
    this.formHtml = formHtml;
  }
}
