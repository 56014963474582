
import { Component, Vue } from "vue-property-decorator";
import Photos from "./Photos.vue";
import { galleryImageData } from "../utils/image_ids";

@Component({
  components: {
    Photos,
  },
})
export default class Galleries extends Vue {
  galleryImageData = galleryImageData;
}
