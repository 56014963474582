
import { Component, Vue } from "vue-property-decorator";
import { TrackingService } from "@/core/Services/TrackingService/TrackingService";

@Component({})
export default class Navigation extends Vue {
  public isOnAbout = false;
  public isOnPrizes = false;
  public isOnRules = false;
  public isOnGallery = false;
  public isOnHome = false;
  mounted(): void {
    document.addEventListener("DOMContentLoaded", this.DOMHandler());
  }

  beforeUnmount(): void {
    document.removeEventListener("DOMContentLoaded", this.DOMHandler());
  }
  private DOMHandler() {
    return () => {
      const homeDiv = document.querySelector(".home");
      homeDiv?.addEventListener("scroll", this.isScrolledIntoView);
    };
  }

  moveTo(id: string): void {
    const el = document.getElementById(id);
    if (el) {
      TrackingService.trackPage(`/${id.replace("__scroll-point", "")}`);
      el.scrollIntoView({ behavior: "smooth" });
    }
  }

  isScrolledIntoView(): void {
    const home = document.getElementById("capture__scroll-point");
    if (home) {
      const rect = home?.getBoundingClientRect();
      if (rect.bottom + 60 <= 0) {
        this.isOnHome = false;
      } else {
        this.isOnHome = true;
      }
    }

    const sections = [
      { id: "about__scroll-point", prop: "isOnAbout", visibleHeight: 0 },
      { id: "prizes__scroll-point", prop: "isOnPrizes", visibleHeight: 0 },
      { id: "rules__scroll-point", prop: "isOnRules", visibleHeight: 0 },
      { id: "gallery__scroll-point", prop: "isOnGallery", visibleHeight: 0 },
      { id: "scoreboard__scroll-point", prop: "isOnGallery", visibleHeight: 0 },
    ];

    // Calculate visible height for each section
    sections.forEach((section) => {
      let htmlElement = document.getElementById(section.id);
      if (htmlElement) {
        const rect = htmlElement.getBoundingClientRect();
        if (rect.top >= 0 && rect.bottom <= window.innerHeight) {
          // Entire section is in view
          section.visibleHeight = rect.height;
        } else if (rect.top < 0 && rect.bottom > 0) {
          // Only bottom part of the section is in view
          section.visibleHeight = rect.bottom;
        } else if (
          rect.top < window.innerHeight &&
          rect.bottom > window.innerHeight
        ) {
          // Only top part of the section is in view
          section.visibleHeight = window.innerHeight - rect.top;
        }
      }
    });

    // Determine the section with the maximum visible height
    const mostVisibleSection = sections.reduce((prev, current) => {
      return prev.visibleHeight > current.visibleHeight ? prev : current;
    });

    // Reset all and set the most visible section as active
    this.isOnAbout =
      this.isOnPrizes =
      this.isOnRules =
      this.isOnGallery =
        false;
    if (mostVisibleSection.visibleHeight > 0)
      this[mostVisibleSection.prop] = true;
  }
}
