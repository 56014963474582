import { render, staticRenderFns } from "./Scoreboard.vue?vue&type=template&id=3ab7513c&scoped=true&"
import script from "./Scoreboard.vue?vue&type=script&lang=ts&"
export * from "./Scoreboard.vue?vue&type=script&lang=ts&"
import style0 from "./Scoreboard.vue?vue&type=style&index=0&id=3ab7513c&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3ab7513c",
  null
  
)

export default component.exports