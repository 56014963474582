
import { Component, Vue } from "vue-property-decorator";
import { mobileOrTablet } from "@/utils/mobileUtils";
import { TrackingService } from "@/core/Services/TrackingService/TrackingService";
@Component({
  methods: { mobileOrTablet },
})
export default class Menu extends Vue {
  moveTo(id: string): void {
    const el = document.getElementById(id);
    if (el) {
      TrackingService.trackPage(`/${id.replace("__scroll-point", "")}`);
      el.scrollIntoView({ behavior: "smooth" });
    }
  }
}
