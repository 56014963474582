
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  components: {},
})
export default class Flag extends Vue {
  @Prop({ default: "diagonal" }) direction!:
    | "diagonal"
    | "horizontal-right-to-left"
    | "horizontal-left-to-right";

  moved = false;

  togglePosition(): void {
    this.moved = !this.moved;
  }
}
