
import { Component, Vue } from "vue-property-decorator";
import Header from "@/components/common/Header.vue";

@Component({
  components: { Header },
})
export default class App extends Vue {
  mounted(): void {
    document.body.classList.add(this.$route.meta?.bodyClass);
  }
}
