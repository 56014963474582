
import { Component, Vue } from "vue-property-decorator";
import TerminalPreview from "@/components/TerminalPreview.vue";
import Partners from "@/components/Partners.vue";
import Rules from "@/components/Rules.vue";
import Navigation from "@/components/Navigation.vue";
import StartDate from "@/components/StartDate.vue";
import Footer from "@/components/Footer.vue";
import RegisterTeamHomePage from "@/components/RegisterTeamHomePage.vue";
import CaptureIfYouCan from "@/components/CaptureIfYouCan.vue";
import Prizes from "@/components/Prizes.vue";
import About from "@/components/About.vue";
import Header from "@/components/common/Header.vue";
import Gallery from "@/components/Gallery.vue";
import Scoreboard from "@/components/Scoreboard.vue";

@Component({
  components: {
    Scoreboard,
    Gallery,
    Header,
    About,
    Prizes,
    CaptureIfYouCan,
    RegisterTeamHomePage,
    Footer,
    StartDate,
    Navigation,
    Rules,
    Partners,
    TerminalPreview,
  },
})
export default class Dashboard extends Vue {}
