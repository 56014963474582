
import { Component, Prop, Vue } from "vue-property-decorator";
import PhotoSwipeLightbox from "photoswipe/dist/photoswipe-lightbox.esm.js";
import { ImageData } from "../utils/image_ids";
import "photoswipe/dist/photoswipe.css";
import { TrackingService } from "@/core/Services/TrackingService/TrackingService";

@Component({})
export default class Photos extends Vue {
  @Prop() availableImages!: Array<ImageData>;
  @Prop() date!: string;
  @Prop() location!: string;

  images: Array<ImageData> = [];
  showAll = false;
  lightbox: PhotoSwipeLightbox | null = null;

  mounted(): void {
    if (!this.lightbox) {
      this.populateImages();
      this.lightbox = new PhotoSwipeLightbox({
        gallery: ".photos__items",
        children: "a",
        pswpModule: () => import("photoswipe/dist/photoswipe.esm.js"),
        mainClass: "pswp--custom-bg",
      });
      this.lightbox.init();
    }
  }

  unmounted(): void {
    if (this.lightbox) {
      this.lightbox.destroy();
      this.lightbox = null;
    }
  }

  showMore(): void {
    this.showAll = true;
    this.populateImages();
  }

  showLess(): void {
    this.showAll = false;
    this.populateImages();
    this.moveTo("gallery__scroll-point");
  }

  populateImages(): void {
    if (this.showAll) {
      this.images = this.availableImages;
    } else {
      this.images = this.availableImages.slice(0, 21);
    }
  }

  moveTo(id: string): void {
    const el = document.getElementById(id);
    if (el) {
      TrackingService.trackPage(`/${id.replace("__scroll-point", "")}`);
      el.scrollIntoView({ behavior: "smooth" });
    }
  }
}
