
import { Component, Prop, Vue } from "vue-property-decorator";
@Component({})
export default class RegistrationForm extends Vue {
  @Prop() public formHtml!: string;

  mounted(): void {
    const containerElement = window.document.querySelector("#form-container");
    if (containerElement) {
      containerElement.innerHTML = this.formHtml;
      // @ts-ignore
      Vue.loadScript(
        "https://mktdplp102cdn.azureedge.net/public/latest/js/form-loader.js?v=1.84.2007"
      );
    } else {
      console.error("ERROR: #form-container element not present!");
    }
  }
}
