export type ImageData = {
  image: string;
  preview: string;
  key: string;
};

export const galleryImageData: Array<ImageData> = [
  { image: "PB250690.png", preview: "PB250690-150x150.png", key: "PB250690" },
  { image: "PB250730.png", preview: "PB250730-150x150.png", key: "PB250730" },
  { image: "PB250660.png", preview: "PB250660-150x150.png", key: "PB250660" },
  { image: "PB250756.png", preview: "PB250756-150x150.png", key: "PB250756" },
  { image: "PB250658.png", preview: "PB250658-150x150.png", key: "PB250658" },
  { image: "PB250738.png", preview: "PB250738-150x150.png", key: "PB250738" },
  {
    image: "PB250597-scaled.jpg",
    preview: "PB250597-150x150.jpg",
    key: "PB250597",
  },
  { image: "PB250804.png", preview: "PB250804-150x150.png", key: "PB250804" },
  { image: "PB250828.png", preview: "PB250828-150x150.png", key: "PB250828" },
  { image: "PB250683.png", preview: "PB250683-150x150.png", key: "PB250683" },
  { image: "PB250732.png", preview: "PB250732-150x150.png", key: "PB250732" },
  { image: "PB250654.png", preview: "PB250654-150x150.png", key: "PB250654" },
  { image: "PB250785.png", preview: "PB250785-150x150.png", key: "PB250785" },
  { image: "PB250668.png", preview: "PB250668-150x150.png", key: "PB250668" },
  { image: "PB250724.png", preview: "PB250724-150x150.png", key: "PB250724" },
  { image: "PB250751.png", preview: "PB250751-150x150.png", key: "PB250751" },
  { image: "PB250755.png", preview: "PB250755-150x150.png", key: "PB250755" },
  { image: "PB250821.png", preview: "PB250821-150x150.png", key: "PB250821" },
  { image: "PB270644.png", preview: "PB270644-150x150.png", key: "PB270644" },
  { image: "PB250757.png", preview: "PB250757-150x150.png", key: "PB250757" },
  { image: "PB250680.png", preview: "PB250680-150x150.png", key: "PB250680" },
  { image: "PB250831.png", preview: "PB250831-150x150.png", key: "PB250831" },
  { image: "PB250703.png", preview: "PB250703-150x150.png", key: "PB250703" },
  { image: "PB250822.png", preview: "PB250822-150x150.png", key: "PB250822" },
  { image: "PB250691.png", preview: "PB250691-150x150.png", key: "PB250691" },
  { image: "PB250812.png", preview: "PB250812-150x150.png", key: "PB250812" },
  { image: "PB250811.png", preview: "PB250811-150x150.png", key: "PB250811" },
  { image: "PB250685.png", preview: "PB250685-150x150.png", key: "PB250685" },
  { image: "PB250709.png", preview: "PB250709-150x150.png", key: "PB250709" },
  { image: "PB250777.png", preview: "PB250777-150x150.png", key: "PB250777" },
  { image: "PB250753.png", preview: "PB250753-150x150.png", key: "PB250753" },
  { image: "PB250684.png", preview: "PB250684-150x150.png", key: "PB250684" },
  { image: "PB250712.png", preview: "PB250712-150x150.png", key: "PB250712" },
  { image: "PB250787.png", preview: "PB250787-150x150.png", key: "PB250787" },
  {
    image: "PB250621-scaled.jpg",
    preview: "PB250621-150x150.jpg",
    key: "PB250621",
  },
  { image: "PB250728.png", preview: "PB250728-150x150.png", key: "PB250728" },
  { image: "PB250681.png", preview: "PB250681-150x150.png", key: "PB250681" },
  { image: "PB250697.png", preview: "PB250697-150x150.png", key: "PB250697" },
  { image: "PB250771.png", preview: "PB250771-150x150.png", key: "PB250771" },
  {
    image: "PB250794-scaled.jpg",
    preview: "PB250794-150x150.jpg",
    key: "PB250794",
  },
  { image: "PB250769.png", preview: "PB250769-150x150.png", key: "PB250769" },
  { image: "PB250707.png", preview: "PB250707-150x150.png", key: "PB250707" },
  { image: "PB250663.png", preview: "PB250663-150x150.png", key: "PB250663" },
  { image: "PB250847.png", preview: "PB250847-150x150.png", key: "PB250847" },
  { image: "PB250719.png", preview: "PB250719-150x150.png", key: "PB250719" },
  { image: "PB250817.png", preview: "PB250817-150x150.png", key: "PB250817" },
  { image: "PB270634.png", preview: "PB270634-150x150.png", key: "PB270634" },
  { image: "PB270639.png", preview: "PB270639-150x150.png", key: "PB270639" },
  { image: "PB250798.png", preview: "PB250798-150x150.png", key: "PB250798" },
  { image: "PB250839.png", preview: "PB250839-150x150.png", key: "PB250839" },
  { image: "PB250766.png", preview: "PB250766-150x150.png", key: "PB250766" },
  { image: "PB270628.png", preview: "PB270628-150x150.png", key: "PB270628" },
  { image: "PB250803.png", preview: "PB250803-150x150.png", key: "PB250803" },
  { image: "PB250716.png", preview: "PB250716-150x150.png", key: "PB250716" },
  { image: "PB250779.png", preview: "PB250779-150x150.png", key: "PB250779" },
  { image: "PB250717.png", preview: "PB250717-150x150.png", key: "PB250717" },
  { image: "PB250698.png", preview: "PB250698-150x150.png", key: "PB250698" },
  { image: "PB250710.png", preview: "PB250710-150x150.png", key: "PB250710" },
  { image: "PB250838.png", preview: "PB250838-150x150.png", key: "PB250838" },
  { image: "PB250695.png", preview: "PB250695-150x150.png", key: "PB250695" },
  { image: "PB250808.png", preview: "PB250808-150x150.png", key: "PB250808" },
  { image: "PB250614.png", preview: "PB250614-150x150.png", key: "PB250614" },
  { image: "PB250830.png", preview: "PB250830-150x150.png", key: "PB250830" },
  { image: "PB250806.png", preview: "PB250806-150x150.png", key: "PB250806" },
  { image: "PB250733.png", preview: "PB250733-150x150.png", key: "PB250733" },
  { image: "PB250661.png", preview: "PB250661-150x150.png", key: "PB250661" },
  { image: "PB250665.png", preview: "PB250665-150x150.png", key: "PB250665" },
  { image: "PB250679.png", preview: "PB250679-150x150.png", key: "PB250679" },
  { image: "PB270630.png", preview: "PB270630-150x150.png", key: "PB270630" },
  { image: "PB250699.png", preview: "PB250699-150x150.png", key: "PB250699" },
  {
    image: "PB250608-scaled.jpg",
    preview: "PB250608-150x150.jpg",
    key: "PB250608",
  },
  { image: "PB250678.png", preview: "PB250678-150x150.png", key: "PB250678" },
  { image: "PB250776.png", preview: "PB250776-150x150.png", key: "PB250776" },
  { image: "PB250696.png", preview: "PB250696-150x150.png", key: "PB250696" },
  { image: "PB250702.png", preview: "PB250702-150x150.png", key: "PB250702" },
  { image: "PB250792.png", preview: "PB250792-150x150.png", key: "PB250792" },
  { image: "PB250609.png", preview: "PB250609-150x150.png", key: "PB250609" },
  { image: "PB250763.png", preview: "PB250763-150x150.png", key: "PB250763" },
  { image: "PB270631.png", preview: "PB270631-150x150.png", key: "PB270631" },
  { image: "PB250752.png", preview: "PB250752-150x150.png", key: "PB250752" },
  { image: "PB250613.png", preview: "PB250613-150x150.png", key: "PB250613" },
  { image: "PB250754.png", preview: "PB250754-150x150.png", key: "PB250754" },
  { image: "PB250662.png", preview: "PB250662-150x150.png", key: "PB250662" },
  { image: "PB250829.png", preview: "PB250829-150x150.png", key: "PB250829" },
  { image: "PB250701.png", preview: "PB250701-150x150.png", key: "PB250701" },
  { image: "PB250721.png", preview: "PB250721-150x150.png", key: "PB250721" },
  { image: "PB250856.png", preview: "PB250856-150x150.png", key: "PB250856" },
  { image: "PB270645.png", preview: "PB270645-150x150.png", key: "PB270645" },
  { image: "PB250711.png", preview: "PB250711-150x150.png", key: "PB250711" },
  {
    image: "PB250793-scaled.jpg",
    preview: "PB250793-150x150.jpg",
    key: "PB250793",
  },
  { image: "PB250810.png", preview: "PB250810-150x150.png", key: "PB250810" },
  { image: "PB250726.png", preview: "PB250726-150x150.png", key: "PB250726" },
];
