
import { Component, Vue } from "vue-property-decorator";
import TerminalPreview from "@/components/TerminalPreview.vue";
import { RegistrationChallengeService } from "@/core/Services/RegistrationChallenge/RegistrationChallengeService";
import { TrackingService } from "@/core/Services/TrackingService/TrackingService";

@Component({
  components: { TerminalPreview },
})
export default class ChallengeTerminal extends Vue {
  send_to_terminal = "";

  banner = {
    emoji: {},
    sign: "wpctf ~$",
  };
  commands = [
    {
      name: "list-commands",
      get(): string {
        return "Available commands:\nsingle-cat &lt;file-name&gt;\nls [file-name]";
      },
    },
  ];
  mounted(): void {
    this.send_to_terminal = "-------------------------------------------";
    setTimeout(() => {
      this.send_to_terminal = "Security protection enabled for flag.txt:";
      setTimeout(() => {
        this.send_to_terminal = "Use of string 'flag' in commands is denied.";
        setTimeout(() => {
          this.send_to_terminal = "-------------------------------------------";
        }, 5);
      }, 5);
    }, 5);
  }

  prompt(value: string): void {
    TrackingService.eventRegistrationShellCommand(value);
    const split_input = value.split(" ");
    const cmd = split_input[0];
    let argument = "";
    if (split_input.length > 1) {
      argument = split_input[1];
    }
    this.send_to_terminal = "";
    setTimeout(() => {
      let commandOutput = RegistrationChallengeService.shell_execute_command(
        cmd,
        argument
      );
      if (commandOutput.startsWith("solved:")) {
        const html = commandOutput.replace("solved:", "");
        this.$emit("challenge-solved", html);
      } else {
        this.send_to_terminal = commandOutput;
        // Scroll to the bottom of the shell, timeout is needed to have time to update the scrollHeight
        setTimeout(() => {
          const shellElement = document.querySelector(
            ".challenge-terminal .shell"
          );
          if (shellElement) {
            shellElement.scrollTo(0, shellElement.scrollHeight);
          }
        }, 0);
      }
    }, 0);
  }
}
