
import { Component, Vue } from "vue-property-decorator";

@Component({})
export default class ScoreboardTable extends Vue {
  get teams(): { place: number; team: string; score: string }[] {
    return [
      { place: 1, team: "Pearak", score: "2125" },
      { place: 2, team: "Mariia", score: "1650" },
      { place: 3, team: "Cyb3rM0nk3ys", score: "1460" },
      { place: 4, team: "pwnlentoni", score: "1410" },
      { place: 5, team: "H4ckan3derl1", score: "1290" },
      { place: 6, team: "SpaghEt", score: "1025" },
      { place: 7, team: "sfogliatelle", score: "590" },
      { place: 8, team: "!TheWinners", score: "345" },
      { place: 9, team: "Bahmut", score: "320" },
      { place: 10, team: "cod3bust3rs", score: "145" },
      { place: 11, team: "CTF-1.13", score: "125" },
      { place: 12, team: "Ninja", score: "125" },
      { place: 13, team: "univr.exe", score: "75" },
      { place: 14, team: "Come Unzipped", score: "40" },
    ];
  }
}
